.App {
  text-align: center;
  background-color: #1a5ce0;
}

.App select {
  width: 95vw;
  max-width: 240px;
  padding: 5px;
  font-size: 20px;
  border-radius: 16px;
  margin-bottom: 10px;
}

.emoji {
  font-size: calc(32px + 10vmin)
}

.tip {
  background-color: #4c7783;
    padding: 10px;
    border-radius: 10px;
}

label, .normal-font {
  font-size: initial;
}

span.kruzkuj {
  background-image: url("/public/circle.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 6px;
  font-weight: 700;
}

.App-content {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 1.5vmin);
  color: #fff;
  transition: .25s;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;

}

.App-link {
  margin-top: 1rem;
  color: #fff;
}


.question-info{

    font-size: 66.666%;
    opacity: .9;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.App-progress {
  background: #fff;
  border-radius: 20px;
  height: 28px;
  width: 100%;
  padding: 4px;
}

.App-progress div {
  display: block;
  background: #003ebb;
  width: 50%;
  height: 100%;
  border-radius: 18px;
  transition: .9s;
}