body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

button {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: white;
  border: 2px solid #003ebb;
  color: #1a5ce0;
  transition: .25s;
  width: 90vw;
  max-width: 800px;

}

p {
  margin-left: 5vw;
  margin-right: 5vw;
}


a {
  display: inline-block;
}

a{
  transition: .25s;
}

a:hover, a:active, button:hover, button:active {
  transform: scale(1.05);
}

p a:hover, p a:active {
  transform: scale(1.0125);
}

a {
  color: #e5f1f4;
}

a:hover {
  opacity: .9;
}



a:active, button:active {
  opacity: .75;
  transform: scale(1.05);
}

p a:active {
  transform: scale(1.025);
}